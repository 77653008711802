$color-primary: #F15A21;

$color-one: #f05a22;
$color-one-light: lighten($color-one, 30%);
$color-one-dark: #a53b14;

$color-two: #535353;
$color-two-light: #9a9a9a;
$color-two-dark: #363636;

$basic-radius: 5px;
