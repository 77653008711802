@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap');

@import "variables";
@import "mixin";
@import "font";

.highlight {
  color: $color-one !important;
}

/* Basic layout
 ------------------------------------------ */

.content_table {
  margin-bottom: 1rem;

  .content_headline {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content_holder {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 25px;
    color: black;
  }
}

/* Buttons
 ------------------------------------------ */

.btn-primary-smweb {
  @include btn-admin($color-primary, #fff);

  i {
    color: black;
  }
}

.btn-cancel-smweb {
  @include btn-admin($color-two-light, black);

  &:hover {
    color: black;
    background-color: white;
  }
}

/* Tables
 ------------------------------------------ */

table.smweb-admin-table {
  border-spacing: 0 10px;
  border-collapse: separate;

  thead {
    th {
      color: white;
      font-weight: normal;
      border: none;
    }
  }

  tbody {
    tr {
      background: rgba(255, 255, 255, 0.5);

      td {
        border: none;
        color: black;

        &.controls {
          width: 1%;
          white-space: nowrap;

          a {
            color: black;
            opacity: 0.5;
            text-decoration: none;

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      &:hover {
        td {
          background: white;
        }
      }
    }
  }
}
