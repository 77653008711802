@import "base";
@import "components/forms";

.alert-primary {
  color: black;
  background-color: #F15A21;
  border-color: #F15A21;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Muli', sans-serif;
}

.body {
  background: radial-gradient(circle, #3E4448 0%, #26292C 74.03%, #000000 100%);
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.loginscreen {
  width: 600px;

  @media (max-width: 600px) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .head {
    color: white;
    margin-bottom: 1rem;

    .logo {
      float: left;
      width: 64px;
      height: 64px;
      background: url('../../images/admin/logo.svg') no-repeat center center;
      background-size: 100%;
      margin-right: 20px;
    }

    h2 {
      display: block;
      font-size: 20px;
      padding-top: 10px;
      line-height: 14px;
      font-weight: bold;
    }

    a {
      display: block;
      color: white;
    }
  }

  form {
    .input-row {
      margin-top: 20px;

      label {
        display: block;
        color: white;
        font-weight: bold;
        font-size: 15px;
      }

      input {
        width: 100%;
        @include border-radius(100px);
        border: 0px;
        background: rgba(255, 255, 255, 0.3);
        padding: 7px 15px;
        color: white;

        &:focus {
          outline: none;
        }
      }

      &.checkbox {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        margin-top: 10px;

        input[type="checkbox"] {
          display: none;

          &:checked + span.checker {
            position: relative;

            &::after {
              position: absolute;
              width: 80%;
              height: 80%;
              top: 2px;
              left: 2px;
              content: '';
              display: block;
              background: $color-primary;
            }
          }
        }

        .checker {
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 1px solid #74777A;
          margin-right: 10px;
          margin-bottom: -4px;
        }

        label {
          display: block;
          margin-top: 8px;
          cursor: pointer;
        }
      }

      &.login {
        text-align: center;
      }
    }

    button {
      padding: 10px 40px;
      font-size: 15px;
      color: $color-primary;
      @include border-radius(100px);
      background: black;
      border: 0px;
      cursor: pointer;
      @include transition(0.3s);

      &:hover {
        background: $color-primary;
        color: black;
      }
    }
  }
}
